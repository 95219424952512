

<template>
   <div class="realtimeDataChart">
      <div class="title">{{title}}</div>
      <div class="chart" v-if='chartType==="chart_price_3"' id="chart_price_3" style="width:100%;"></div>
      <div class="chart" v-else-if='chartType==="chart_volumn_3"' id="chart_volumn_3" style="width:100%;"></div>
      <div class="chart" v-else-if='chartType==="chart_roder_book_3"' id="chart_roder_book_3" style="width:100%;"></div>
      <div class="chart" v-else-if='chartType==="chart_macd_3"' id="chart_macd_3" style="width:100%;"></div>
   </div>
</template>

<script>

import Highcharts from 'highcharts/highstock';
import HighchartsMore from 'highcharts/highcharts-more';

HighchartsMore(Highcharts)


export default {
   props:{
      title:{
         type:String,
         required:false,
      },
      chartType:{
         type:String,
         required:false,
      },
   },
   data(){
      return {

      }
   },
   methods:{
      // 更新图表
      updateChart(seriesData){

         if(this.tickerDataChart){
            this.tickerDataChart.destroy()  // 销毁原有的图表
         }
         this.tickerDataChart = Highcharts.stockChart(this.chartType, {
            chart: {
               zoomType: null,
               // pinchType: null
            },
            rangeSelector: {
               selected: 4
            },
            yAxis: {
               labels: {
                  formatter: function () {
                     return (this.value > 0 ? ' + ' : '') + this.value + '%';
                  }
               },
               plotLines: [{
                  value: 0,
                  width: 2,
                  color: 'silver'
               }]
            },
            plotOptions: {
               series: {
                  compare: 'percent',
                  showInNavigator: true
               }
            },
            tooltip: {
               // formatter:function () {
               //    return "aa";
               // },
               pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.change}%)<br/>',
               valueDecimals: 2,
               followTouchMove: false,
               split: true
            },
            series:seriesData,
            // series:[
            //    {
            //       name:this.title,
            //       data:seriesData
            //    }
            // ]
         });
      },
   }
   
}
</script>


<style scoped>

.title{
   font-size: 18px;
   font-weight: bold;
   padding: 2px 6px;
   padding-bottom:0px;
}
.chart{
  /* height:calc(100% - 20px); */
  height:300px
}

</style>