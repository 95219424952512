<template>
   <div class="container">

      <div class="collectionList list">
         <div class="item" :class="{active:collectionNameActive==item}" v-for="(item,index) in collectionNameList" :key="index" @click="clickCollection(item)">{{item}}</div>
      </div>

      <div class="stockList list">
         <div class="item" :class="{active:stockActive.code==item.code}" v-for="(item,index) in stockList" :key="index" @click="clickStock(item)">
            <div class="name">{{item.name}}</div>
            <div class="code">{{item.code}}</div>
         </div>
      </div>
      <div class="realtimeData">

         <!-- 3秒股票 -->
         <realtime-data-chart ref='chart_price_3'  title="3秒价格" chartType='chart_price_3'></realtime-data-chart>

         <!-- 3秒交易量 -->
         <realtime-data-chart ref='chart_volumn_3'  title="3秒交易量" chartType='chart_volumn_3'></realtime-data-chart>
         
         <!-- 实时摆盘 -->
         <realtime-data-chart ref='chart_roder_book_3'  title="实时摆盘" chartType='chart_roder_book_3'></realtime-data-chart>
         
         <!-- 3盘MACD -->
         <realtime-data-chart ref='chart_macd_3' title="3盘MACD" chartType='chart_macd_3'></realtime-data-chart>
      </div>

   </div>
</template>

<script>

import RealtimeDataChart from "./RealtimeDataChart"

export default {
   components:{
      RealtimeDataChart
   },
   data(){
      return {
         collectionNameList:[],
         collectionNameActive:"",
         stockList:[],
         stockActive:{},
         tickerDataChart:null,

         data_price_3:[],    // 3秒交易量
         data_volumn_3:[],   // 3秒交易量 
         data_order_book:[], // 实时摆盘
         data_macd_3:[],     // 3秒MACD     
         
         webSocket:null,           // websocket对象
         webSocketStatus:"未连接",  // websocket连接状态
         reconnectNumber:0,        // websocket重连次数
      }
   },
   mounted(){
      this.webSocketConnect();
   },
   methods:{
      // 集合列表项点击事件
      clickCollection(item){
         this.collectionNameActive = item;
         this.webSocket.send(JSON.stringify({
            operationType:'realtimeData',
            operation:'getStockList',
            collectionName:item
         }));
      },
      // 股票列表项点击事件
      clickStock(item){
         this.stockActive = item;
         this.webSocket.send(JSON.stringify({
            operationType:'realtimeData',
            operation:'getRealtimeData',
            code:item.code,
            collectionName:this.collectionNameActive
         }));
      },
      // .0 建立webSocket连接 -------------------------------------------------------------
      webSocketConnect(){

         let that = this;

         // 官方文档：https://developer.mozilla.org/zh-CN/docs/Web/API/WebSocket
         this.webSocketStatus = "正在连接……";
         if(this.$env === "production"){
            this.webSocket = new WebSocket("ws://118.24.103.166:49151");
         }else{
            this.webSocket = new WebSocket("ws://127.0.0.1:49151");
         }
         
         // 连接成功回调
         this.webSocket.onopen = function(evt) { 
            that.reconnectNumber = 0;
            that.webSocketStatus = "连接成功";
            that.webSocket.send(JSON.stringify({
               token:'123456'
            }));
         };
         
         // 收到服务器数据回调
         this.webSocket.onmessage = function(evt) {
            try {
               let data = JSON.parse(evt.data);
               if(data.operation === "getCollectionNameList"){
                  that.collectionNameList = data.collectionNameList.sort().reverse();
                  that.collectionNameActive = data.collectionNameList[0];
               }else if(data.operation === "getStockList"){
                  that.stockList = data.stockList;
                  that.stockActive = data.stockList[0];
               }else{
                  let tempObj = null;
                  let hours8  = 8 * 3600 // 图表默认以0时区显示时间，此处加8小时进行较正
                  that.data_price_3    = [];
                  that.data_volumn_3   = [];
                  that.data_order_book = [];
                  that.data_macd_3     = [];

                  // 0.1> 处理价格和交易量
                  if(data.data_ticker_3.length > 0){
                     let data_ticker_3 = data.data_ticker_3[0].data;
                     let data_ticker_3_count = data_ticker_3.length;
                     
                     let priceData = {
                        name:"价格",
                        data:[]
                     }
                     let volumnData = {
                        name:"交易量",
                        data:[]
                     }
                     for(let i=0;i<data_ticker_3_count;i++){
                        tempObj = JSON.parse(data_ticker_3[i])
                        priceData.data.push([(tempObj.t+hours8)*1000 ,tempObj.p])
                        volumnData.data.push([(tempObj.t+hours8)*1000,tempObj.v])
                     }
                     that.data_price_3.push(priceData)
                     that.$refs.chart_price_3.updateChart(that.data_price_3)
                     that.data_volumn_3.push(volumnData)
                     that.$refs.chart_volumn_3.updateChart(that.data_volumn_3)
                  }

                  // 0.2> 处理摆盘数据
                  if(data.data_order_book.length > 0){
                     let data_order_book = data.data_order_book[0].data;
                     let data_order_book_count = data_order_book.length;

                     let buyData = {
                        name:"买盘",
                        data:[]
                     }
                     let sellData = {
                        name:"卖盘",
                        data:[]
                     }
                     for(let i=0;i<data_order_book_count;i++){
                        tempObj = data_order_book[i]
                        let askTime = new Date(tempObj.svr_recv_time_ask).getTime()/1000;
                        let bidTime = new Date(tempObj.svr_recv_time_bid).getTime()/1000;
                        buyData.data.push([(askTime+hours8)*1000 ,tempObj.Ask.ask_volume1])
                        sellData.data.push([(bidTime+hours8)*1000 ,tempObj.Bid.ask_volume1])
                     }
                     that.data_order_book.push(buyData)
                     that.data_order_book.push(sellData)
                     that.$refs.chart_roder_book_3.updateChart(that.data_order_book)
                  }

                  // 0.3> 处理MACD数据
                  if(data.data_macd_3.length > 0){
                     let data_macd_3 = data.data_macd_3[0].data;
                     let data_macd_3_count = data_macd_3.length;

                     let difData = {
                        name:"DIF差离",
                        data:[]
                     }
                     let deaData = {
                        name:"DEA(DIF差离均线)",
                        data:[]
                     }
                     let macdData = {
                        name:"MACD(DIF与DEA差离大小)",
                        data:[]
                     }
                     
                     for(let i=0;i<data_macd_3_count;i++){
                        tempObj = data_macd_3[i]
                        difData.data.push([(tempObj.time+hours8)*1000 ,tempObj.dif])
                        deaData.data.push([(tempObj.time+hours8)*1000 ,tempObj.dea])
                        macdData.data.push([(tempObj.time+hours8)*1000 ,tempObj.macd])
                     }
                     that.data_macd_3.push(difData)
                     that.data_macd_3.push(deaData)
                     that.data_macd_3.push(macdData)
                     that.$refs.chart_macd_3.updateChart(that.data_macd_3)
                  }
                 
               }
            } catch(e) {
               console.log(evt.data);
               that.webSocket.send(JSON.stringify({
                  operationType:'realtimeData',
                  operation:'getCollectionNameList'
               }));
            }            
         };
         
         // 连接关闭后回调
         this.webSocket.onclose = function(evt) {
            that.webSocketStatus = "连接关闭";
         };    
         // 连接出错后回调
         this.webSocket.onerror = function(evt) {
            that.webSocket.close();
            that.webSocketStatus = "连接失败";
            that.webSocketReconnect(); // 连接失败自动重连
         };    
      },
      
      // 2.1 webSocket重新连接 -------------------------------------------------------------
      webSocketReconnect(){
         ++this.reconnectNumber;
         this.webSocket = null;
         this.webSocketConnect();
      }
   },
   // Vue生命周期：页面离开之前被调用
   beforeDestroy: function () {
      this.webSocket.close();
      this.webSocket = null;
   }
   
}
</script>

<style scoped>

.container{
   display:flex;
   flex-direction:row;
}

/* 公共列表样式 */
.list{
   display:flex;
   flex-direction: column;
   border-right: 1px solid rgb(223, 223, 223);
   padding:8px 0px;
   overflow:auto;
}
.list>.item{
   display:flex;
   flex-direction: column;
   padding:4px 10px;
   cursor: pointer;
}
.list>.item:hover{
   background:rgb(244, 244, 244);
}
.list>.item.active{
   background:rgb(230, 230, 230);
   font-weight: bold;
}


/* 集合列表 */
.collectionList{
   width:200px;
   min-width:200px;
   max-width:200px;
}

/* 股票列表 */
.stockList{
   width:300px;
   min-width:300px;
   max-width:300px;
}
.stockList>.item>.name{
   overflow: hidden;
   text-overflow: ellipsis; /* 文本溢出元素时省略号 */
   white-space: nowrap;     /* 规定段落中的文本不换行 */
}
.stockList>.item>.code{
   font-size:12px;

}

/* 逐笔数据 */
.realtimeData{
   display:flex;
   flex-grow: 1;
   flex-direction:column;
   overflow:auto;
}
.realtimeData>.realtimeDataChart{
   flex-grow: 1;
}
</style>